import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import FeaturesEvents from '../components/common/sections/featuresEvents';
import CarouselSimilar from '../components/common/sections/carouselSimilar';

const EventPage = ({ data }) => {
  const { eventData, eventsData } = data;
  const { childImageSharp, fields, id } = eventData;
  const filterEvents = eventsData.edges.filter(({ node }) => node.id !== id);

  return (
    <>
      <SeoDetails
        createdNode
        nodeImage={childImageSharp}
        seoContent={fields.seoContent}
      />
      <HeroImage
        createdNode
        nodeImage={childImageSharp}
        seoContent={fields.seoContent}
      />
      <FeaturesEvents eventData={fields} />
      <CarouselSimilar itemData={filterEvents} />
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    eventsData: allFile(
      filter: { fields: { localEvent: { eq: "true" } } }
      sort: { fields: fields___endDate, order: ASC }
      limit: 5
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          fields {
            id
            seoContent {
              menuTitle
              pageTitle
              slug
              description {
                description
              }
            }
            startDate
            endDate
          }
        }
      }
    }
    eventData: file(id: { eq: $id }, fields: { localEvent: { eq: "true" } }) {
      id
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      fields {
        localEvent
        id
        name
        seoContent {
          pageTitle
          menuTitle
          slug
          description {
            description
          }
        }
        startDate
        endDate
        venue
      }
    }
  }
`;

export default EventPage;
