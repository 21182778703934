import styled from 'styled-components';

const Left = styled.div`
  @media (min-width: ${props => props.theme.responsive.medium}) {
    float: left;
    display: block;
    margin-right: 5%;
    width: 45%;
  }
  h3 {
    text-transform: uppercase;
  }
`;

export default Left;
