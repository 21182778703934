import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  z-index: 10;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding-bottom: 50px;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
