import styled from 'styled-components';

const Inner = styled.div`
  position: relative;
  display: block;
  max-width: 1000px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
`;

export default Inner;
