import styled from 'styled-components';

const BookingTrigger = styled.div`
  position: relative;
  margin-top: 2em;
  transition: all 0.3s ease-in-out;
  button,
  a {
    border: none;
    overflow: visible;
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: 0 1.5rem;
    overflow: initial !important;
    &:first-child {
      margin-right: 20px;
    }
    @media (max-width: ${(props) => props.theme.responsive.small}) {
      width: 100%;
      &:first-child {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
`;

export default BookingTrigger;
